<template>

	<div>
		<div class="block-header">
		</div>

		<div class="container-fluid">
			<div class="row clearfix">
				<div class="col-lg-12">
					<div class="card">
						<div class="body">

							<div>
								<el-button v-if="operation!=='查看'" type="primary" size="medium" style="float: right;" @click="submit">
									{{operation}}
								</el-button>
								<el-page-header @back="go(`/project-pre-trial-list/${page}`)" :content="'入园预审项目--'+operation">
								</el-page-header>
							</div>

							<el-form ref="form" label-width="160px" size="small">

								<el-form-item label="填报单位">
									<el-row>
										<el-col :span="10">
											<el-tag type="success">{{form.填报单位}}</el-tag>
										</el-col>
									</el-row>
								</el-form-item>

								<el-row>
									<el-col :span="12">
										<el-form-item label="项目名称" required>
											<el-input v-model="form.项目名称"></el-input>
										</el-form-item>
									</el-col>

								</el-row>

								<el-row>

									<el-col :span="10">
										<el-form-item label="建设地点" required>
											<el-input v-model="form.建设地点" ></el-input>
										</el-form-item>
									</el-col>


								</el-row>

								<el-row>
									<el-col :span="12">
										<el-form-item label="建设内容" required>
											<el-input type="textarea" :rows="4" v-model="form.建设内容" ></el-input>
										</el-form-item>
									</el-col>

								</el-row>

								<el-row>

									<el-col :span="10">
										<el-form-item label="产业类别" required>
											<el-select v-model="form.产业类别">
												<el-option label="工业" value="工业"></el-option>
												<el-option label="农业" value="农业"></el-option>
												<el-option label="服务业" value="服务业"></el-option>
											</el-select>
										</el-form-item>
									</el-col>
								</el-row>

								<el-row>
									<el-col :span="12">
										<el-form-item label="用地面积" required>
											<el-input v-model="form.用地面积"></el-input>
										</el-form-item>
									</el-col>
								</el-row>

								<el-row>

									<el-col :span="10">
										<el-form-item label="投资方名称" required>
											<el-input v-model="form.投资方名称"></el-input>
										</el-form-item>
									</el-col>

									<el-col :span="10">
										<el-form-item label="总投资额(万元)" required>
											<el-input-number :min="1" v-model="form.总投资额"></el-input-number>
										</el-form-item>
									</el-col>

									<el-col :span="10">
										<el-form-item label="投资方所属地" required>
											<place-select v-model="form.投资方所属地"></place-select>
										</el-form-item>
									</el-col>
								</el-row>

								<el-row>

									<el-col :span="10">
										<el-form-item label="联系人" required>
											<el-input v-model="form.联系人"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="10">
										<el-form-item label="联系人电话" required>
											<el-input v-model="form.联系人电话"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="10">
										<el-form-item label="联系人职务" required>
											<el-input v-model="form.联系人职务"></el-input>
										</el-form-item>
									</el-col>

								</el-row>

								<el-row>

									<el-col :span="10">
										<el-form-item label="跟踪人" required>
											<el-input v-model="form.跟踪人"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="10">
										<el-form-item label="跟踪单位" required>
											<el-input v-model="form.跟踪单位"></el-input>
										</el-form-item>
									</el-col>

								</el-row>

								<el-row>

									<el-col :span="10">
										<el-form-item label="项目进展情况" required>
											  <el-select v-model="form.项目进展情况" placeholder="请选择">
											    <el-option label="待审核(已提交入园材料)" value="待审核(已提交入园材料)"> </el-option>
												<el-option label="已通过预审" value="已通过预审"> </el-option>
												<el-option label="未通过预审" value="未通过预审"> </el-option>
											  </el-select>
										</el-form-item>
									</el-col>
									<el-col :span="10">
										<el-form-item label="项目存在问题" required>
											<el-input v-model="form.项目存在问题" ></el-input>
										</el-form-item>
									</el-col>

								</el-row>

								<el-form-item v-if="operation!=='审批' && operation!=='填报'" label="审批信息">
									<el-form-item>
										{{form.审批状态+" "+	(form.审批意见?form.审批意见:'')}}
									</el-form-item>
								</el-form-item>

								<el-row v-if="operation==='审批'">
									<el-col :span="18">
										<el-form-item label="审批">
											<el-radio-group v-model="form.审批状态">
												<el-radio-button label="待审批"></el-radio-button>
												<el-radio-button label="审批通过"></el-radio-button>
												<el-radio-button label="审批未通过"></el-radio-button>
											</el-radio-group>
										</el-form-item>
									</el-col>
								</el-row>

								<el-row v-if="operation==='审批'">
									<el-col :span="18">
										<el-form-item label="审批意见">
											<el-input v-model="form.审批意见" :autosize="{ minRows: 2, maxRows: 4}"
												type="textarea"></el-input>
										</el-form-item>
									</el-col>
								</el-row>

							</el-form>


						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import PlaceSelect from '../components/PlaceSelect.vue'
	import projectPreTrialController from "../controller/projectPreTrialController.js"
	import MultipleFileUpload from '../components/MultipleFileUpload.vue';
	import {projectPreTrialValidate} from '@/assets/js/check.js'
	export default {
		components: {
			MultipleFileUpload,PlaceSelect
		},
		data() {
			return {
				operation: '',
				id: '',
        page:1,
				isApproval: false,
				form: {},
				user: {},
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					},
					shortcuts: [{
						text: '今天',
						onClick(picker) {
							picker.$emit('pick', new Date());
						}
					}, {
						text: '昨天',
						onClick(picker) {
							const date = new Date();
							date.setTime(date.getTime() - 3600 * 1000 * 24);
							picker.$emit('pick', date);
						}
					}, {
						text: '一周前',
						onClick(picker) {
							const date = new Date();
							date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', date);
						}
					}]
				},
			}
		},
		created() {
			this.id = this.getParam('id');
			this.isApproval = this.getParam('approval') === 'approval'
			this.user = JSON.parse(sessionStorage.getItem('user'))
      this.page = this.getParam('page');
      console.log(this.page)

			if (this.isApproval) {
				this.operation = '审批'
				this.initForm();
			} else if (this.id) {
				this.operation = '修改'
				this.initForm();
			} else {
				this.operation = '填报'
				this.form.填报单位 = this.user.dept_name
			}

		},
		methods: {
			initForm() {
				projectPreTrialController.get({
					unique: this.id
				}).then(res => {
					this.form = res.data;
					if(this.form.modified.status<0){
						this.operation = '查看';
					}
				})
			},
			submit() {
				if (!projectPreTrialValidate(this.form,this))
					return;

				let obj = this.deepClone(this.form)

				if (this.isApproval) {
					projectPreTrialController.approval(obj).then(res => {this.go(`/project-pre-trial-list/${this.page}`)})
				} else if (obj.id) {
					projectPreTrialController.put(obj).then(res => {this.go(`/project-pre-trial-list/${this.page}`)})
				} else {
					projectPreTrialController.post(obj).then(res => {this.go(`/project-pre-trial-list/${this.page}`)})
				}

			},
			
		}

	}
</script>

<style scoped>
	.el-page-header {
		margin-bottom: 40px;
	}

	.el-date-picker {
		cursor: pointer;
	}
</style>
